import Axios from 'axios'
import router from '../../router'
import Cookies from 'vue-cookies'
import { ElMessageBox, ElMessage, ElLoading } from 'element-plus'
import { Decimal } from 'decimal.js'
import lang from './language.js'

// =========================================================
// =========================================================
// POST访问方法$utils.post(obj)
// GET访问方法$utils.get(obj)
// obj参数解析：
// obj.baseURL--------(String)----访问后台地址
// obj.url------------(String)----访问后台ACA地址
// obj.params---------(Object)----入参
// obj.responseType---(String)----接收返回值类型
// obj.confirm--------(Boolen)----开启/关闭操作提醒（默认值：false）
// obj.confirmText----(String)----操作提醒文本（默认值：是否执行该操作），设置文本默认开启操作提醒
// obj.confirmType----(String)----操作提醒类型（默认值：warning(警告)），设置文本默认开启操作提醒
// obj.confirmTitle---(String)----操作提醒标题（默认值：提示），设置文本默认开启操作提醒
// obj.showLoading----(Boolen)----开启/关闭加载框（默认值：false）
// obj.headers--------(Object)----自定义header属性（默认设置：Authorization，Content-Type）
// obj.noasync--------(Boolen)----是否关闭异步（默认值：false）
// ********************* noasync: true 以下参数不生效 ******************************
// obj.diyok----------(Function)--访问成功，操作最外层返回数据方法
// obj.ok-------------(Function)--访问成功，操作返回成功时数据方法
// obj.diyerror-------(Function)--访问成功，操作返回数据错误方法
// obj.detailOk-------(Function)--访问成功，返回数据错误时，统一处理报错方法
// obj.error----------(Function)--访问错误时，自定义处理错误信息
// obj.detailError----(Function)--访问错误时，统一处理报错方法
// obj.hideErrorInfo--(Boolen)----是否展示默认错误
// --------------------同步数据调用方法参考---------------------
// return $utils.post({
//   url: '/api/login/getJwtToken',
//   params: { 
//     username: 'admin02',
//     password: '123123'
//   },
//   showLoading: true,
//   noasync: true
// })
// --------------------异步数据调用方法参考---------------------
// $utils.post({
//   url: '/api/login/getJwtToken',
//   params: { 
//     username: 'admin02',
//     password: '123123'
//   },
//   showLoading: true,
//   ok: function (res) {
//     console.log(res)
//     console.log(2)
//   }
// })
// =========================================================
// =========================================================
// 基础配置
Axios.defaults.withCredentials = true
// const baseURL = 'https://192.168.0.109:7136'
// const baseURL = new URL(window.location.href).hostname == 'localhost'
// ?'https://192.168.0.109:7136':'https://box.p11.com.cn'
const baseURL = ''

const utils = {
  version: '1.0.1',
  imgapiurl: 'https://box.p11.com.cn',
  messageBox: ElMessageBox,
  message: ElMessage,
  cookies: Cookies,
  lg: lang.language[Cookies.get('lgtype')?Cookies.get('lgtype'):'cn'],
  // axios的get请求
  get: function (obj) {            
    obj.type = 'get'
    return this.isConfirm(obj)
  },
  // axios的post请求
  post: function (obj) {
    obj.type = 'post'
    return this.isConfirm(obj)
  },
  isConfirm: function (obj) {
    let that = this
    if (!obj.confirmText) {
      obj.confirmText = '是否执行该操作'
    } else {
      obj.confirm = true
    }
    if (!obj.confirmType) {
      obj.confirmType = 'warning'
    } else {
      obj.confirm = true
    }
    if (!obj.confirmTitle) {
      obj.confirmTitle = '提示'
    } else {
      obj.confirm = true
    }
    if (!obj.confirm) {
      return that.sendHttp(obj)
    } else {
      ElMessageBox.confirm(obj.confirmText, obj.confirmTitle, {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: obj.confirmType,
      }).then(() => {
        return that.sendHttp(obj)
      }).catch(() => {
        console.log('取消')
      })
    }
  },
  // axios数据请求访问
  sendHttp: function (obj) {
    let that = this
    let loading_flag = null
    if (!obj.headers) {
      Axios.defaults.headers = {
        "Authorization": Cookies.get('token')?Cookies.get('token') : '',
        "Content-Type": obj.isGet?'multipart/form-data':'application/json',
        "Sysversion": that.version
      }
    } else {
      obj.headers["Authorization"] = Cookies.get('token')?Cookies.get('token') : ''
      obj.headers["Sysversion"] = that.version
      Axios.defaults.headers = obj.headers
    }

    if (!obj.showLoading) {

    } else {
      loading_flag = ElLoading.service({
        lock: true,
        text: obj.loadingText?obj.loadingText:'处理中……',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
    }
    if (!obj.baseURL) {
      Axios.defaults.baseURL = baseURL 
    } else {
      Axios.defaults.baseURL = obj.baseURL
    }
    if (!obj.noasync) {
      Axios({
        method: obj.type,
        url: obj.url,
        data: obj.params,
        responseType: obj.responseType?obj.responseType:''
      }).then((res) => {
        if (!loading_flag) {

        } else {
          loading_flag.close()
        }
        if (res.data.code == 0) {
          if (obj.ok) {
            obj.ok(res.data)
          }
        } else {
          that.detailOk(res)
        }
      }).catch((e) => {
        if (!loading_flag) {
    
        } else {
          loading_flag.close()
        }
        if (obj.error) {
          obj.error(e)
        } else {
          that.detailError(e, !obj.hideErrorInfo)
        }
      })
    } else {
      return new Promise(function (resolve, reject) {
        Axios({
          method: obj.type,
          url: obj.url,
          data: obj.params,
          responseType: obj.responseType?obj.responseType:''
        }).then((res) => {
          if (!loading_flag) {
    
          } else {
            loading_flag.close()
          }
          if (res.data.code != 0) {
            that.detailOk(res)
          }
          resolve(res)
        }).catch((e) => {
          if (!loading_flag) {
    
          } else {
            loading_flag.close()
          }
          reject(e)
        })
      })
    }

    // Axios({
    //   method: obj.type,
    //   url: obj.url,
    //   data: obj.params,
    //   responseType: obj.responseType?obj.responseType:''
    // }).then((res) => {
    //   if (!loading_flag) {

    //   } else {
    //     loading_flag.close()
    //   }
    //   if (obj.diyok) {
    //     obj.diyok(res)
    //   } else {
    //     if (res.data.code == 0) {
    //       if (obj.ok) {
    //         obj.ok(res.data)
    //       }
    //     } else {
    //       if (obj.diyerror) {
    //         obj.diyerror(res)
    //       } else {
    //         that.detailOk(res)
    //       }
    //     }
    //   }
    // }).catch((e) => {
    //   if (obj.error) {
    //     obj.error(e)
    //   } else {
    //     that.detailError(e, !obj.hideErrorInfo)
    //   }
    // })
  },
  // 通用请求成功访问
  detailOk: function (res) {
    var resmsg = ''
    if (res.data.code == 999) {
      ElMessageBox({
        type: 'warning',
        message: res.data.message
      }).then(() => {
        window.location.reload(true)
      })
    } else {
      if (!res.data.data) {
        resmsg = res.data.message
      } else {
        resmsg = res.data.message + '请联系管理员'
      }
      ElMessage({
        type: 'warning',
        message: resmsg
      })
    }
  },
  // 通用请求错误处理
  detailError: function (e, flag) {
    console.log('detailError')
    console.log(e)
    let errorCode = e.response.status
    if (flag) {
      switch (errorCode) {
        case 'ERR_NETWORK':
          console.log('网络连接错误，访问不到后台')
          break
        case 500:
          console.log(e.response.data)
          break
        case 401:
          ElMessageBox.confirm('登录超时请重新登录', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'error',
          }).then(() => {
            this.outLogin()
          }).catch(() => {
            this.outLogin()
          })
          break
        default:
          ElMessageBox({
            type: 'warning',
            message: e.response.status + ':' + e.response.statusText
          })
          break;
      }
    } else {
      switch (errorCode) {
        case 'ERR_NETWORK':
          console.log('网络连接错误，访问不到后台')
          break
        case 500:
          console.log(e.message)
          break
        case 401:
          console.log('e.message2')
          console.log(e.message)
          console.log(router)
          ElMessageBox.confirm('检测到您的账号在其他地方登录', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'error',
          }).then(() => {
            this.outLogin()
          }).catch(() => {
            this.outLogin()
          })
          break
        default:
          console.log('e.message2')
          console.log(e)
          break;
      }
    }
  },
  // 退出登录
  outLogin: function () {
    this.cookies.set('username', '')
    this.cookies.set('password', '')
    router.push({path: '/'})
  },
  // 字母全转换为小写
  utilToLowerCase: function (obj) {
    let menukeys = []
    let newlist = {}
    menukeys = Object.keys(obj)
    for (let j = 0; j < menukeys.length; j++) {
      newlist[menukeys[j].toLowerCase()] = obj[menukeys[j]]
    }
    return newlist
  },
  // 除了PageIndex，PageSize，PageSizes，TotalCount，OrderBy重置所有查询条件
  resetForm: function (searchdata) {
    const keys = Object.keys(searchdata)
    const resArr = JSON.parse(JSON.stringify(searchdata))
    for (var i = 0; i < keys.length; i++) {
      if (keys[i].toLowerCase() != 'page'.toLowerCase() && 
       keys[i].toLowerCase() != 'pageIndex'.toLowerCase() && 
       keys[i].toLowerCase() != 'limit'.toLowerCase() && 
       keys[i].toLowerCase() != 'pageSize'.toLowerCase() && 
       keys[i].toLowerCase() != 'PageSizes'.toLowerCase() && 
       keys[i].toLowerCase() != 'totalItemCount'.toLowerCase() && 
       keys[i].toLowerCase() != 'OrderBy'.toLowerCase()) {
        switch (Object.prototype.toString.call(searchdata[keys[i]])) {
          case '[object String]':
            resArr[keys[i]] = ''
            break;
          case '[object Number]':
            resArr[keys[i]] = 0
            break;
          case '[object Boolean]':
            resArr[keys[i]] = false
            break;
          case '[object Null]':
            resArr[keys[i]] = null
            break;
          case '[object Object]':
            resArr[keys[i]] = {}
            break;
          case '[object Function]':
            resArr[keys[i]] = function () {}
            break;
          case '[object Array]':
            resArr[keys[i]] = []
            break;
          case '[object Date]':
            resArr[keys[i]] = new Date()
            break;
          default:
            resArr[keys[i]] = ''
            break;
        }
      }
    }
    console.log(resArr)
    return resArr;
  },

  deepCopy: function (data) {
    return JSON.parse(JSON.stringify(data))
  },
  noRepeat: function (arr){
    var newArr = [...new Set(arr)]; //利用了Set结构不能接收重复数据的特点
    return newArr
  },
  disableWheel: function (evt) {
    evt = evt || window.evt
    if (evt.preventDefault) {  
      // Firefox  
      evt.preventDefault()  
      evt.stopPropagation()  
    } else {  
      // IE  
      evt.cancelBubble=true
      evt.returnValue = false
    }  
    return false 
  },
  showCloseTip: function (callback) {
    var that = this
    ElMessageBox.confirm('是否关闭页面？未保存数据将不存在', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning',
    }).then(() => {
      if (callback) {
        callback()
      }
    }).catch(() => {
      
    })
  },
  func_auth: function (obj) {
    var that = this
    var menuPath = ''
    if (!obj.url) {
      menuPath = obj.route.path
    } else {
      menuPath = obj.route.path + obj.url
    }
    this.post({
      url: '/Api/System/User/GetFuncListByPath',
      params: {
        menuPath: menuPath
      },
      ok: function (res) {
        if (obj.detail) {
          obj.detail(res)
        }
      }
    })

    
  },
  // // 忘记干啥用的了=- =
  // checkData: function (oldData, newData) {
  //   var flag = false
  //   var keys = Object.keys(oldData)
  //   var newkeys = Object.keys(newData)
  //   if (keys.length < newkeys.length) {
  //     return true
  //   }
  //   for (var i = 0; i < keys.length; i++) {
  //     var oldstr = '', nowstr = ''
  //     if (keys[i].indexOf('Date') >= 0 || keys[i].indexOf('date') >= 0 || keys[i].indexOf('time') >= 0 || keys[i].indexOf('Time') >= 0) {
  //       oldstr = new Date(oldData[keys[i]]).getTime()
  //       nowstr = new Date(newData[keys[i]]).getTime()
  //     } else {
  //       oldstr = oldData[keys[i]]
  //       nowstr = newData[keys[i]]
  //     }
  //     if (keys[i] == 'dateRange') {
  //       continue
  //     }
  //     if (oldstr != nowstr) {
  //       flag = true
  //       break
  //     }
  //   }
  //   return flag
  // },

  numFormatter: function (val) {
    if (typeof val != 'string') {
      val = val.toString()
    }
    val = val.replace(/[^\d|,|.]+/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    return val
  },

  numParser: function (val) {
    if (typeof val != 'string') {
      val = val.toString()
    }
    val = val.replace(/\$\s?|(,*)/g, '')
    return val
  },
  
  downloadFile: function (obj) {
    // name文件名
    // url下载地址
    var that = this
    ElMessageBox.confirm('是否下载文件' + obj.name, '提示', {
      type: 'warning'
    }).then((item) => {
      that.post({
        url: obj.url,
        params: obj.params,
        responseType: 'blob',
        headers: {"Content-Type": 'multipart/form-data'},
        diyok: function (res) {
          console.log('DownloadFiles')
          console.log(res)
          let blob = new Blob([res.data], {
            type: 'application/vnd.ms-excel'
          })
          console.log(blob)
          let fileName = obj.name
          if (window.navigator.msSaveOrOpenBlob) {
            navigator.msSaveBlob(blob, fileName)
          } else {
            var link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob)
            link.download = fileName
            link.click()
            //释放内存
            window.URL.revokeObjectURL(link.href)
          }
        }
      })
    }).catch()
  },


  numAdd: function (data1, data2, digit) {
    const num1 = new Decimal(data1)
    const num2 = new Decimal(data2)
    if (!digit && digit !== 0) {
      return num1.add(num2).toNumber()
    } else {
      return num1.add(num2).toNumber().toFixed(digit)
    }
  },
  numSub: function (data1, data2, digit) {
    const num1 = new Decimal(data1)
    const num2 = new Decimal(data2)
    if (!digit && digit !== 0) {
      return num1.sub(num2).toNumber()
    } else {
      return num1.sub(num2).toNumber().toFixed(digit)
    }
  },
  numMul: function (data1, data2, digit) {
    const num1 = new Decimal(data1)
    const num2 = new Decimal(data2)
    if (!digit && digit !== 0) {
      return num1.mul(num2).toNumber()
    } else {
      return num1.mul(num2).toNumber().toFixed(digit)
    }
  },
  numDiv: function (data1, data2, digit) {
    const num1 = new Decimal(data1)
    const num2 = new Decimal(data2)
    if (!digit && digit !== 0) {
      return num1.div(num2).toNumber()
    } else {
      return num1.div(num2).toNumber().toFixed(digit)
    }
  },

}

export default utils
// function A(){
//   var funs=[];
//   for(var i=0;i<10;i++){
//     funs[i]= (function (num) {
//       return function () {
//         console.log(num)
//       }
//     })(i)
//   }
//   return funs; 
// }
// var funs = A();//定义funs[0]-funs[9]，10个函数
// console.log(funs[0]());//10
// console.log(funs[1]());//10
// console.log(funs[6]());//10