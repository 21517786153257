<template>
  <!-- ============================================用户管理====================================== -->
   <!-- <div>

   </div> -->
  <el-container style="padding: 10px;background-color: rgb(250, 250, 251);z-index: 10;">
    <el-header style="height: 85px;padding-right: 10px;padding-left: 10px;">
      <div style="display: flex;align-items: center;height: 40px;">

        <i v-if="props.config.showIcon" @click="showSecondMenu" :class="['iconfont', 
      store.state.show_second_menu_box=='hide_second_menu_box'?'icon-zhankai':'icon-shouqi',
       'icon_set']" style="margin-right: 10px;"></i>

        <div style="flex-grow:1;font-size: 16px;font-weight: bold;">
          <el-breadcrumb :separator-icon="ArrowRight">
            <el-breadcrumb-item v-for="(item, index) in breadcrumb" :key="index">{{ item }}</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
        <div style="display:flex;align-items: center;">
          <!-- <div style="margin-right:15px;">
            <el-input @input="keyWordSerach()" v-model="searchData.keyWord" size="small" placeholder="关键字搜索" />
          </div> -->
          <!-- <el-button size="small" type="info" icon="Refresh" plain @click="reset()">重置</el-button> -->
          <slot name="title_btn"></slot>
          <el-button v-if="!props.config.noAdd" size="small" type="success" icon="CirclePlus" @click="addData()">{{ $utils.lg.modal_table_add }}</el-button>
          <!-- <el-button size="small" type="success" icon="CirclePlus" @click="openCanvasDialogFn()">绘制板</el-button> -->
        </div>
      </div>
      <div style="display: flex;align-items: center;height: 45px;">
        <!--  @input="keyWordSerach()" -->
        <el-input v-if="!props.config.noKeyWord" style="width:150px;margin-right: 15px;" v-model="searchData.keyWord" size="small" :placeholder="$utils.lg.modal_table_search_ph1" />
        <slot name="easy_search_slot" :adv_search="searchData"></slot>
        <el-button-group style="margin-right:15px;">
          <el-button size="small" type="primary" icon="Search" @click="btnSearch()">{{ $utils.lg.modal_table_search }}</el-button>
          <!-- <el-button size="small" type="primary" @click="isshowdrawer = true">{{ $utils.lg.modal_table_moresearch }}</el-button> -->
        </el-button-group>
        <el-button size="small" type="info" icon="Refresh" plain @click="reset()">{{ $utils.lg.modal_table_reset }}</el-button>
      </div>
    </el-header>
    <el-main>
      <!-- @cell-dblclick="cellDBClick" -->
      <!-- :header-cell-style="$store.state.outheaderstyle" -->
      <el-table class="diytablebox"
      style="--table-bgc: blue;"
      border
      ref="tableRef" 
      height="100%"
      :data="tableData"
      :row-style="(e) => tableRowStyle(e, rowStyle)"
      :header-cell-style="tableheaderstyle"
      @row-click="rowClick"
      @filter-change="filterChange" 
      @selection-change="handleSelectionChange" 
      @sort-change="sortChange"
      @row-contextmenu="rowContextMenu"
      row-key="id"
      size="small">
				<el-table-column v-if="!props.config.table_config.hideSelection" type="selection" fixed="left" width="30"></el-table-column>
				<el-table-column v-if="props.config.table_config.hideSerial" type="index" fixed="left" :label="$utils.lg.modal_table_index" width="50"></el-table-column>
				<el-table-column v-for="(item, index) in props.config.table_config.column" :key="index"
					v-bind="getColumnAttribute(item)" show-overflow-tooltip>
					<template #default="scope" v-if="item.slot">
						<slot :name="item.prop" :row="scope.row" :_index="scope.$index"></slot>
					</template>
				</el-table-column>
					
        <el-table-column v-if="!props.config.table_config.noOp" align="center" :width="props.config.table_config.opeWidth?props.config.table_config.opeWidth:'140px'" fixed="right" :label="$utils.lg.table_operation">
          <template #default="scope">
            <div style="display: flex;justify-content: flex-end;">
              <slot name="table_btn" :row="scope.row" :_index="scope.$index"></slot>
              <el-button v-if="!props.config.table_config.noEdit" size="small" @click="handleEdit(scope.$index, scope.row)">{{$utils.lg.modal_table_op_edit}}</el-button>
              <el-button v-if="!props.config.table_config.noDelete" size="small" type="danger" @click="handleDelete(scope.row, 1)">{{$utils.lg.modal_table_op_delete}}</el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </el-main>
    <el-footer>
      <div style="display: flex;align-items: center;height: 45px;">
        <div style="flex-grow: 1">
          <slot name="bottom_left" :get_choose_info="get_choose_data()"></slot>
          <el-button v-if="!props.config.table_config.noDeleteMore" size="small" 
          style="" type="danger" icon="Delete" @click.stop="deletechoose">{{$utils.lg.modal_table_op_deletes}}</el-button>
        </div>
        <!-- <el-pagination
          size="small"
          background
          layout="prev, pager, next"
          :total="50"
          class="mt-4"
        /> -->
        <el-pagination
          v-if="!props.config.noPaged"
          v-model:current-page="searchData.pageIndex"
          v-model:page-size="searchData.pageSize"
          :page-sizes="searchData.pageSizes"
          :background="true"
          size="small"
          layout="total, sizes, prev, pager, next, jumper"
          :total="searchData.totalItemCount"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        />
      </div>
    </el-footer>
    <!-- =================================== 弹出层 ================================ -->
    <el-dialog custom-class="diyDialog" class="diyDialog" 
    v-model="openDialog" :before-close="(done) => beforeClose(done)" 
    draggable
    destroy-on-close
    :title="props.config.dialog_title?props.config.dialog_title:$utils.lg.modal_dialog_title" 
    :width="props.config.dialog_width?props.config.dialog_width:'1050px'"
    :style="`min-width: ${props.config.dialog_min_width?props.config.dialog_min_width:'1050px'};`">
    
      <div class="contentbox_border" :style="'max-height: ' + ($store.state.dialog_body_height) + 'px;overflow-y: auto;'">
				<slot name="dialog_slot" :data="sendData"></slot>
			</div>
      
      <template #footer>
        <div class="dialog-footer">
          <div style="display: flex;justify-content: flex-end;">
            <slot name="dialog_btn" :row="currentTableData"></slot>
            <el-button @click="clickClose()">{{$utils.lg.dialog_close}}</el-button>
          </div>
        </div>
      </template>

    </el-dialog>
    <!-- =========================================================================== -->

    <el-drawer
      v-model="isshowdrawer"
      :title="$utils.lg.modal_table_moresearch"
      direction="rtl"
    >
      <!-- :before-close="drawerHandleClose" -->
      <slot name="search_slot" :adv_search="searchData">
        <div class="flexbox" style="margin-bottom:10px;">
          <div class="searchContent">企业名称：</div>
          <div class="searchBox" style="width:100%;">
            <el-input placeholder="请输入企业名称" v-model="searchData.enterpriseName"></el-input>
          </div>
        </div>
      </slot>

      <template #footer>
        <div style="flex: auto">
          <el-button size="small" @click="isshowdrawer=false">{{$utils.lg.dialog_close}}</el-button>
          <el-button size="small" type="info" icon="Refresh" @click="reset()">{{$utils.lg.modal_table_reset}}</el-button>
          <el-button size="small" type="primary" @click="btnSearch()">{{$utils.lg.modal_table_search}}</el-button>
        </div>
      </template>
    </el-drawer>

    <!-- ====================================右键菜单================================== -->
    <context-menu-pop>
      <template #pop_body>
        <div style="cursor: pointer;padding:3px 0;font-size: 14px;">
          <slot name="contextMenu_slot" :row="contextMenuData">
            <div class="clickitem" @click="contextMenuClick()">
              <div class="itemleft">功能1</div>
              <div class="itemright"></div>
            </div>
            <div class="clickitem" @click="contextMenuClick()">
              <div class="itemleft">功能2</div>
              <div class="itemright"></div>
            </div>
            <div class="clickitem" @click="contextMenuClick()">
              <div class="itemleft">功能3</div>
              <div class="itemright"></div>
            </div>
          </slot>
        </div>
        
      </template>
    </context-menu-pop>


  </el-container>
</template>

<script setup>
  import ContextMenuPop from '@/components/Jm_ContextMenu/jm_popMenu.vue'

  import { reactive, ref, onMounted, inject, nextTick, defineProps, defineEmits, defineExpose } from 'vue'
  import { useStore } from 'vuex'
  // 注入全局utils工具js
  const $utils = inject('$utils')
  // 注入全局filters工具js
  const $filters = inject('$filters')
  // 定义store
  const store = useStore()
  // 设置行的颜色，空值为默认值
  const rowStyle = reactive({ id: '', color: '' })
	// 定义$emit事件
	const emit = defineEmits()

  const tableheaderstyle = ref({
      'background-color': '#337ECC',
      'color': 'rgb(255, 255, 255)',
      // 'background-color': '#F5F7FA',
      // 'color': 'rgb(96, 98, 102)',
      'font-weight': 'bold'
    })



  // 
  const dialogSwitch = ref('')

  // 定义子组件的ref
  const data_detail = ref()

  // 定义定时器
  const timmer = ref(null)

	

	const props = defineProps({
		config: {
				type: Object,
				default: {
          dialog_min_width: '1150px',
          dialog_width: '90%',
          showIcon: false,
          // 标题
          breadcrumb: [],
          // 是否开启右键事件
          openContextMenu: false,
					// 基础信息配置
					operation: {
						// 获取表单列表数据URL——GetPageList
						get_data_url: '',
						// 获取表单列表详情数据URL——GetEdit
						get_detaildata_url: '',
						// 删除表单列表数据URL——DoBatchDelete
						del_data_url: '',
						// 配置弹出哪个弹窗内容
						checkref: 'data_detail'
					},
					table_config: {
            // 是否隐藏序号
            hideSerial: false,
            // 设置操作行宽度
            opeWidth: '',
						// 是否隐藏多选行
						hideSelection: false,
						// 是否不开启单行删除
            noDelete: false,
						// 是否不开启编辑功能
            noEdit: false,
						// 是否不开启批量删除
            noDeleteMore: false,
						// 表格信息配置
						column: []
					},
          cellDBClick: false
				}
		}
	})
	const operationData = props.config.operation

	// 传输到父组件的值
	const sendData = ref({})


  // *********************************定义变量**********************************
  // 页眉
  const breadcrumb = ref([])
  // 是否开启详细查询
  const isshowdrawer = ref(false)
  // 筛选条件分布
  const filterData = ref({
    userName: [
      { text: '测试1', value: '测试1' }, 
      { text: '测试2', value: '测试2' }
    ]
  })
  // 定义table的ref绑定
  const tableRef = ref()
  // 筛选条件
  const searchData = ref({
    pageIndex: 1,
    pageSizes: [5, 10, 15, 30, 100, 200],
    pageSize: 15,
    totalItemCount: 100,
    keyWord: ''
  })
  // 批量选择的数据
  const chooseTableData = ref([])
  // 初始化表格数据
  const tableData = ref([])
  // 当前点击的数据
  const currentTableData = ref({})
  // 是否开启弹窗
  const openDialog = ref(false)
  // 右键获取的值
  const contextMenuData = ref({})
  // ******************************************************* 进入页面时方法 *************************************************************

  // 进入该模块执行的方法
  const onMountedHook = function () {
    console.log('进入该modal模块执行的方法')
    initSetInfo()
    btnSearch()
  }

  // 遍历基础设置
  function initSetInfo () {
    breadcrumb.value = props.config.breadcrumb?props.config.breadcrumb:['测试小标题']
    tableheaderstyle.value = props.config.tableheaderstyle?props.config.tableheaderstyle:tableheaderstyle.value
    searchData.value = Object.assign(searchData.value, props.config.searchDefault?props.config.searchDefault:{})
  }

  
  // 是否展示二级菜单
  function showSecondMenu() {
    let str = ''
    if (store.state.show_second_menu_box == '' || store.state.show_second_menu_box == 'show_second_menu_box') {
      str = 'hide_second_menu_box'
    } else {
      str = 'show_second_menu_box'
    }
    store.commit('set_show_second_menu_box', str)
  }

	// ################# 动态配置Column表格属性值
	function getColumnAttribute(obj) {
		return obj
		// const resObj = {}
		// for (let key in obj) {
		// 	resObj[key] = obj[key]
		// }
		// return resObj
	}

  // ********************************************************************************************************************
  // ------------------ 右键菜单事件 ---------------------------
  function rowContextMenu(row, column, event) {
    contextMenuData.value = row
    rowStyle.id = row.id
    if (!props.config.openContextMenu) return
    event.preventDefault()
    store.commit('set_pop_attr', {
      pop_show: true, 
      get_event: event
    })
   
  }
  // ----------------- 默认右键点击选择菜单事件 -------------------------
  function contextMenuClick() {
    store.commit('set_pop_attr', {
      pop_show: false
    })
  }
  // **************************************************************************

  // **************************************************************************

  // // 保存数据提交方法
  // function saveData() {
	// 	emit('checkData', {
	// 		checkref: operationData.checkref,
	// 		fn: 'submitData'
	// 	})
  // }
  // 输入关键字查询
  function keyWordSerach() {
    if (timmer.value) {
      clearTimeout(timmer.value)
    }
    timmer.value = setTimeout(() => {
      btnSearch()
    }, 300)
  }
  // 查询第一页数据方法
  function btnSearch() {
    searchData.value.pageIndex = 1
    getData()
  }
  // 获取数据方法
  function getData() {
    // console.log('getData')
    tableData.value = []
		if (!operationData.get_data_url) {
			for (let i = 0; i < 40; i++) {
			  tableData.value.push({
			    userName: '测试' + i,
					nickName: '昵称' + i,
			    id: i
			  })
			}
			return
		}
    if (operationData.initSearchData) {
      searchData.value = Object.assign(searchData.value, operationData.initSearchData)
    }
    // console.log(searchData.value)
    $utils.post({
      url: operationData.get_data_url,
      params: searchData.value,
      showLoading: true,
      ok: function (res) {
        if (props.config.detailGetData) {
          tableData.value = props.config.detailGetData(res.data)
        } else {
          tableData.value = res.data
        }
        if (!props.config.noPaged) {
          searchData.value.totalItemCount = res.paged.totalCount
        }
      }
    })
  }
  // 新建列表方法
  function addData() {
    if (props.config.addData) {
      props.config.addData()
    } else {
      showView()
      emit('checkData', {
        checkref: operationData.checkref,
        fn: 'initData',
        data: {},
        type: 'add'
      })
    }

  }
  // 编辑数据方法
  async function handleEdit(index, item) {
    // 查询详情数据
    const res = await getPageData(item)
    if (res.data.code == 0) {
      showView()
      currentTableData.value = res.data.data
      // console.log(currentTableData.value)
			emit('checkData', {
				checkref: operationData.checkref,
				fn: 'initData',
				data: res.data.data,
        type: 'edit'
			})

    }

  }
  // 删除数据方法
  function handleDelete(item, flag) {
    // 删除方法相应的字段
    const del_Id = 'id'
    const ids = []
    if (flag == 0) {
      item.forEach(item => {
        ids.push(item[del_Id])
      })
    } else {
      ids.push(item[del_Id])
    }
		if (!operationData.del_data_url) return
    $utils.post({
      url: operationData.del_data_url,
      params: { ids: ids },
      confirm: true,
      showLoading: true,
      ok: function (res) {
        getData()
      }
    })
  }
  // 自定义批量操作事件
  function get_choose_data () {
    let ids = []
    for (let i = 0; i < chooseTableData.value.length; i++) {
      ids.push(chooseTableData.value[i].id)
    }
    return {
      ids: ids,
      data: chooseTableData.value
    }
  }
  // 批量删除数据方法
  function deletechoose() {
    if (chooseTableData.value.length == 0) {
      $utils.message({
        showClose: true,
        center: true,
        message: $utils.lg.modal_op_tip1,
        type: 'warning'
      })
    } else {
      handleDelete(chooseTableData.value, 0)
    }
  }
  function getPageData(item) {
    // console.log(item)
		if (!operationData.get_detaildata_url) return { data: { code: 0, data: { orderCode: '123' } } }
    return $utils.post({
      url: operationData.get_detaildata_url,
      params: {
        id: item.id
      },
      showLoading: true,
      noasync: true
    })
  }
  // ====================== 关闭弹窗事件 ===========================
  // 筛选展示的弹窗组件
  function showView() {
    openDialog.value = true
    // dialogSwitch.value = type
  }
  function beforeClose(done, flag) {
    if (flag || store.state.close_showtip) {
      $utils.showCloseTip(function () {
        done()
        getData()
      })
    } else {
      done()
      getData()
    }
    if (props.config.beforeCloseFn) {
      emit('table_evt', {
        row: {},
        type: 'beforeClose'
      })
    }
  }
  function closeDialog() {
    openDialog.value = false
    getData()
  }
  function onlySearch() {
    getData()
  }
  function clickClose() {
    openDialog.value = false
    if (props.config.beforeCloseFn) {
      emit('table_evt', {
        row: {},
        type: 'beforeClose'
      })
    }
  }
  // *******************************修改分页*******************************
  // 改变每页条数方法
  function handleSizeChange(val) {
    searchData.value.pageSize = val
    getData()
  }
  // 改变页数方法
  function handleCurrentChange(val) {
    searchData.value.pageIndex = val
    getData()
  }
  // **************************************************************

  // *******************************Table操作*******************************
  // 表格行数据设计
  function tableRowStyle(table, obj) {
    var rowstyle = ''
    if (table.rowIndex % 2 != 0) {
      rowstyle = 'background-color: #FAFAFA;'
    }
    if (!obj.id && obj.id != 0) {
      
    } else {
      if (table.row.id == obj.id) {
        if (!obj.color) {
          rowstyle = 'background-color: #c6e2ff;color: #337ECC;font-weight:bold;'
          // rowstyle = 'background-color: #c6e2ff;color: #409EFF;font-weight:bold;'
        } else {
          rowstyle = 'background-color: ' + obj.color + ';'
          // #00D6E1
        }
      }
    }
    if (!!obj.field) {
      if (table.row[obj.field]) {
        rowstyle = 'background-color: #ccc;'
      }
    }
    return rowstyle
  }
  // 多选数据
  function handleSelectionChange(val) {
    chooseTableData.value = val
  }
  // 行单击事件
  function rowClick(row, column, e) {
    rowStyle.id = row.id
    currentTableData.value = row
  }
  // 标题头筛选
  function filterChange(filters) {
    const key = Object.keys(filters)[0]
    searchData.value[key] = filters[key]
    getData()
  }
  // 行双击事件
  function cellDBClick(row, column, cell, event) {
    if (props.config.cellDBClick) {
      emit('table_evt', {
        row: row,
        type: 'cellDBClick'
      })
    } else {
      handleEdit(1, row)
    }
  }
  // 排序筛选
  function sortChange(obj) {
    if (!obj.prop) {
      searchData.value.orderBy = ''
    } else {
      searchData.value.orderBy = obj.prop + ' ' + obj.order
    }
    getData()
  }
  
  // 重置查询条件方法
  function reset() {
    $utils.messageBox.confirm($utils.lg.modal_op_tip2, $utils.lg.confirm_tip, {
      confirmButtonText: $utils.lg.dialog_check,
      cancelButtonText: $utils.lg.dialog_cancel,
      type: 'warning',
    }).then(() => {
      tableRef.value.clearFilter()
      searchData.value = $utils.resetForm(searchData.value)
      btnSearch()
    }).catch(() => {
      
    })
    
  }
  // **************************************************************
  // // 外部自定义 弹窗及按钮 事件
  // function diyBtnFn(obj) {
  //   showView()
  //   emit('checkData', obj)
  // }
  function changeTableData (obj) {
    currentTableData.value = Object.assign(currentTableData.value, obj.changeFeild)
    let get_data = tableData.value.find(item => item.id == obj.id)
    get_data = Object.assign(get_data, obj.changeFeild)
  }

  onMounted(() => {
    onMountedHook()
  })


  // 高级筛选条件传入并搜索
  function advSearch(fn) {
    if (!!fn) {
      fn()
    }
    btnSearch()
  }
  // 高级筛选条件传入并搜索
  function data_Search(obj) {
    searchData.value = Object.assign(searchData.value, obj)
    if (obj.roleId == '') {
      delete searchData.value.roleId
    }
    btnSearch()
  }

	defineExpose({
    showView,
    // 仅查询数据不关闭窗口
    onlySearch,
    // 关闭窗口并查询新数据
		closeDialog,
    // 仅关闭窗口
    clickClose,
    // // 外部自定义 弹窗及按钮 事件
    // diyBtnFn,
    // 高级筛选条件传入并搜索
    advSearch,
    // 查询方法
    getData,
    // 更改数据
    changeTableData,
    data_Search
	})

</script>

<style scoped>
.el-container {
  height: 100%;
  border: none;
}
.el-header {
  background-color:#fafafb;
  border: 1px solid #DDE2E8;
  border-bottom:none;
  border-radius: 5px 5px 0 0;
  border: none;
}
.el-main {
  padding: 0;
  background-color:#DEDEDE;
  border: 1px solid #DDE2E8;
  border: none;
}
.el-footer {
  height:45px;
  /* border-radius: 0 0 5px 5px; */
  background-color:#fafafb;
  border:1px solid #DDE2E8;
  border-top:none;
  border: none;
}
/* 去除切换的动画效果 */
.diytablebox :deep(.el-table__body td.el-table__cell) {
  transition: none !important;
}
/* 去除table的hover效果 */
::v-deep .el-table__body tr.hover-row > td{
  background-color: inherit !important;
}
/deep/ .el-dialog__body {
  padding: 0 !important;
  /* padding: 0 15px 0 15px !important; */
}
/deep/ .el-dialog {
  margin: 30px auto 30px !important;
  overflow: hidden !important;
  /* min-width: 1150px; */
}
/deep/ .el-scrollbar__thumb {
  background-color: var(--table-bgc);
}
/* 
.demo-pagination-block + .demo-pagination-block {
  margin-top: 10px;
}
.demo-pagination-block .demonstration {
  margin-bottom: 16px;
} */

</style>